<template>
    <div class="change-schedule">
        <StackRouterHeaderBar :title="$translate('CHANGE_SCHEDULE_DETAIL')" :show-title="true" />

        <div class="schedules">
            <div class="schedule-desc">
                <div class="user-chat-info">
                    <div class="item flex-row" v-for="(item, i) in infos" :key="i">
                        <div class="desc" v-html="item" />
                    </div>
                </div>
            </div>

            <div class="schedule-list">
                <section class="section m-b-15">
                    <div class="items">
                        <div class="items-title m-b-8">일정/시간 변경</div>
                        <span class="items-desc">변경하실 일정을 선택해주세요.</span>
                        <div class="item-choose m-t-8" @click="onClickCalendar()">
                            <div v-if="!originalDateConfirmed">
                                {{ formatDate(new Date()) }}
                            </div>
                            <div v-else>
                                {{ formatDate(originalDateConfirmed) }}
                            </div>
                        </div>
                    </div>
                </section>
                <section class="section m-b-15">
                    <div class="items">
                        <div class="items-title m-b-8">지역 변경</div>
                        <span class="items-desc">변경하실 지역을 선택해주세요.</span>
                        <div class="item-choose m-t-8">
                            <div class="item-place" :style="{ padding: originalMeetPlace ? '7px' : '0' }">
                                {{ originalMeetPlace }}
                                <i class="material-icons i-right f-15" v-if="originalMeetPlace" @click="onClickClose()"
                                    >close</i
                                >
                            </div>
                        </div>
                        <div class="region-selector f-12 m-t-5" @click="showRegionSelector()">지역 선택하기>></div>
                    </div>
                </section>
                <section class="section">
                    <div class="items">
                        <div class="items-title m-b-8">장소 변경</div>
                        <span class="items-desc">
                            변경하실 식당/카페의 네이버 지도를 아래에 입력해주세요. <br />
                            예약을 완료하신 후 해당 페이지에 작성해주세요.
                        </span>
                        <TextareaWithX
                            :isInputMode="true"
                            :placeholder="'장소명 입력(ex. 스타벅스 역삼역점)'"
                            v-model="originalRestaurantName"
                            @input="onInputName"
                            class="m-t-8"
                        />
                        <TextareaWithX
                            :isInputMode="true"
                            :placeholder="'주소명 입력(ex. https://naver.me/)'"
                            v-model="originalRestaurantUrl"
                            @input="onInputUrl"
                            class="m-t-8"
                        />
                    </div>
                </section>
            </div>

            <button class="submitBtn" @click="onClickConfirm">약속 변경 완료하기</button>
        </div>
    </div>
</template>

<script>
import scheduleService from '@/services/schedule'

export default {
    name: 'ChangeScheduleDetailPage',
    data: () => ({
        scheduleInfo: [],
        dateContent: [null],
        originalDateConfirmed: '',
        originalMeetPlace: '',
        originalRestaurantName: null,
        originalRestaurantUrl: null,
        selectedDate: null,
    }),
    props: {
        chat: Object,
    },
    async mounted() {
        this.$bus.$emit('setRootHeader', '약속 변경 사항')

        this.originalDateConfirmed = await this.chat.schedule.date_confirmed
        this.originalMeetPlace = await this.chat.schedule.meet_place
        this.originalRestaurantName = await this.chat.schedule.restaurant_name
        this.originalRestaurantUrl = await this.chat.schedule.restaurant_url
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        infos() {
            return [
                '*상대방과 충분히 조율 후, 변경된 항목에 대하여 입력해주세요.',
                '*약속 변경 완료하기 버튼을 누르시면, 채팅방은 자동으로 닫히게 됩니다.',
            ]
        },
    },
    methods: {
        onInputName(value) {
            this.originalName = value
        },
        onInputUrl(value) {
            this.originalUrl = value
        },
        async onClickCalendar() {
            const res = await this.$modal.custom({
                component: 'ModalSelectDate',
            })
            if (res) {
                const { y, m, d, time } = res
                const originalDate = `${y}-${m}-${d} ${time}`
                const ymd = this.$moment(originalDate).format('YY/MM/DD')
                const day = this.$moment(originalDate).format('dddd').toUpperCase()
                const hourMinute = this.$moment(originalDate).format('A h:mm')
                let submit = false
                this.dateContent = this.dateContent.map((date, i) => {
                    if (!submit) {
                        submit = true
                        return {
                            original: originalDate,
                            parsed: `${ymd} ${this.$translate(day)}, ${hourMinute}`,
                        }
                    } else return null
                })
                if (!this.reschedule) {
                    this.dateContent.sort((a, b) => (((b || {}).original || 0) > ((a || {}).original || 0) ? -1 : 1))
                }
                // date_confirmed를 업데이트
                const dateConfirmed = await this.$moment(originalDate).format('YYYY.MM.DD HH:mm')
                this.originalDateConfirmed = dateConfirmed
            }
        },
        async showRegionSelector() {
            const item = await this.$modal.custom({
                component: 'ModalPromiseRegion',
            })
            if (item) {
                this.selectedRegion = item.name
                this.originalMeetPlace = item.name
            }
        },
        onClickClose() {
            this.originalMeetPlace = ''
        },
        formatDate(date) {
            return this.$moment(date).format('YYYY.MM.DD HH:mm')
        },
        async onClickConfirm() {
            let placeInfo = ''
            if (this.originalRestaurantName) {
                placeInfo = '장소: ' + this.originalRestaurantName + '<br />'
            }

            if (
                this.originalDateConfirmed === this.chat.schedule.date_confirmed &&
                this.originalMeetPlace === this.chat.schedule.meet_place &&
                this.originalRestaurantName === this.chat.schedule.restaurant_name &&
                this.originalRestaurantUrl === this.chat.schedule.restaurant_url
            ) {
                await this.$modal.basic({
                    body: '변경하신 항목이 없습니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'c-primary',
                        },
                    ],
                })
                return
            }

            if (
                (this.originalRestaurantName && this.originalRestaurantUrl === '') ||
                (this.originalRestaurantUrl && this.originalRestaurantName === '') ||
                (this.originalRestaurantUrl === null && this.originalRestaurantName) ||
                (this.originalRestaurantName === null && this.originalRestaurantUrl)
            ) {
                await this.$modal.basic({
                    body: '장소명과 주소명을 모두 입력해주세요.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'c-primary',
                        },
                    ],
                })
                return
            }

            if (this.originalMeetPlace === '') {
                await this.$modal.basic({
                    body: '지역을 입력해주세요.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'c-primary',
                        },
                    ],
                })
                return
            }

            if (this.originalRestaurantUrl && !this.originalRestaurantUrl.includes('naver')) {
                await this.$modal.basic({
                    body: '네이버 지도 주소만 입력할 수 있습니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'c-primary',
                        },
                    ],
                })
                return
            }

            if (
                (this.originalDateConfirmed !== this.chat.schedule.date_confirmed ||
                    this.originalMeetPlace !== this.chat.schedule.meet_place) &&
                this.originalRestaurantName === this.chat.schedule.restaurant_name &&
                this.originalRestaurantName
            ) {
                const idx = await this.$modal.basic({
                    body: `일정 / 시간 혹은 지역이 변경되었지만, 장소가 변경되지 않았어요. 그래도 약속 변경을 완료하시겠습니까?`,
                    buttons: [
                        {
                            label: '아니오',
                            class: 'btn-default',
                        },
                        {
                            label: '네',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (idx === 0) {
                    return
                }
            }

            // 약속장소 입력이 안됐을 때
            if (
                this.chat.schedule.restaurant_name === null &&
                this.originalDateConfirmed !== this.chat.schedule.date_confirmed &&
                this.originalMeetPlace !== this.chat.schedule.meet_place &&
                this.originalRestaurantName === this.chat.schedule.restaurant_name
            ) {
                await this.$modal.basic({
                    body: '추후 장소 입력 안내 메세지를 보내드립니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'c-primary',
                        },
                    ],
                })
            }

            const idx = await this.$modal.basic({
                body:
                    '다음 내용으로 약속이 변경됩니다. <br /><br />' +
                    '일정:' +
                    this.formatDate(this.originalDateConfirmed) +
                    '<br />' +
                    '지역:' +
                    this.originalMeetPlace +
                    '<br />' +
                    placeInfo +
                    '<br />' +
                    '확인을 클릭하시면, 채팅방은 종료됩니다.',
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx === 1) {
                const index = await this.$modal.basic({
                    body: '약속 변경 완료와 함께, 채팅방이 종료되었습니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (index === 0) {
                    this.$router.go(-1)
                    this.$router.go(-1)
                    this.$router.push({ name: 'ScheduleListPage' })

                    const payload = {
                        id: this.chat.schedule.id,
                        user_id: this.me.id,
                        status: 'change_completed',
                        date_confirmed: this.originalDateConfirmed,
                        meet_place: this.originalMeetPlace,
                        restaurant_name: this.originalRestaurantName,
                        restaurant_url: this.originalRestaurantUrl,
                    }
                    try {
                        await scheduleService.changeScheduleConfirm(payload)
                        this.isClicked = true
                    } catch (err) {
                        console.error(err)
                    }
                }
            }
        },
        getDaySchedule(day) {
            const days = ['일', '월', '화', '수', '목', '금', '토']
            return days[day]
        },
    },
    beforeDestroy() {
        this.$bus.$emit('setRootHeader', '')
    },
}
</script>
<style lang="scss" scoped>
.schedules {
    padding: 15px;
}
.schedule-desc {
    background: #d9d9d9;
    border-radius: 4px;
    padding: 8px 12px;

    .item {
        @include spoqa-f-regular;
        font-size: 11px;
        line-height: 25px;
    }
}

.schedule-list {
    padding: 15px;
}
.section {
    padding: 10px;

    .items-title {
        font-size: 13px;
        font-weight: 600;
    }

    .items-desc {
        font-size: 11px;
    }

    .item-desc {
        color: gray;
    }

    .item-choose {
        min-height: 38px;
        padding: 10px;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        .item-place {
            display: block;
            width: fit-content;
            background-color: #d9d9d9;
            border-radius: 8px;
        }
    }

    .region-selector {
        text-align: end;
    }
}

.submitBtn {
    display: block;
    margin: 0 auto;
    padding: 13px 20px;
    border-radius: 8px;
    font-size: 12px;
    background-color: #03006e;
    color: #fff;
    border: none;
    text-align: center;
}
</style>
